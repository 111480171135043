@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Oswald:wght@200;300;400;500&display=swap');

@layer base{
    body{
        @apply font-[Oswald];

    }

    li {
        @apply px-4;
        @apply cursor-pointer;
        @apply hover:text-[#FFFFFF]
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgb(255, 86, 244),
        hsla(242,74%,61%,0.8)
    )!important
}